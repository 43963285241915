import {inject } from 'aurelia-framework';
import {HttpClient, json} from 'aurelia-fetch-client';
import {Router,activationStrategy} from 'aurelia-router';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import {LoginService} from '../../shared/services/loginservice';
import {JwtService} from '../../shared/services/jwtservice';
import {SharedState} from '../../shared/state/sharedstate';
import {config} from '../../shared/services/config';
import { App } from '../../app';
import { MsalService } from '../../shared/services/msalService';

@inject(LoginService, SharedState,JwtService, Router, ValidationControllerFactory,HttpClient,MsalService, App)
export class LoginComponent {
 
  type = '';
  username = '';  
  password = '';
  uname='';
  pword='';
  errors = null;
  sliderImages = [];
  isLoginPage = true;
  routerView = "main-content";
  
  constructor(loginService, sharedState,jwtService, router, controllerFactory,httpClient,MsalService, App) {
    this.loginService = loginService;
    this.sharedState = sharedState;
    this.jwtService = jwtService;
    this.router = router;
    this.httpClient = httpClient;
    this.msalService = MsalService;
    this.user = null;
    this.accessToken = null;
    this.controller = controllerFactory.createForCurrentScope();
    this.app=App;
    
    ValidationRules
      .ensure('username').required()
      .ensure('password').required()     
      .on(this);
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }
  
  activate(params, routeConfig) {
    this.type = routeConfig.name;
  }

  async signIn() {
    const success = await this.msalService.signIn();
    //console.log(success);
    if (success) {
      // Handle successful sign-in
      if (
        (navigator.userAgent.includes('Mac') && navigator.userAgent.includes('Safari')) ||
        (navigator.userAgent.includes('iPhone') && navigator.userAgent.includes('Safari'))
      ) {
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      }
    } else {
      // Handle sign-in failure
    }
  }

  signOut() {
    this.msalService.signOut();
  }

    /**
     * Using app component sending false value so it will hide header and sidebar.
     * added async await to load all images .
     */
  async attached(){
      document.querySelector('router-view').classList.remove(this.routerView);
    this.app.updatePropertyValue(false);
    this.uname = window.localStorage['UserName'];
    this.pword = window.localStorage['Password'];
    this.RememberMe = window.localStorage['RememberMe'];

    await this.loginService.bindImages().then(data=>{
      this.imageSlider =  data;
      this.bannerUrl= config.img_url;
    });

    let i =1;
    let image= await document.querySelectorAll(".banner-image");
    if(image.length > 0){
        image[0].style.opacity='1';
        this.interval = setInterval(function () {
            image.forEach((img,i)=>{
                img.style.opacity='0';
            });
            if(i === image.length){
                i=0;
            }
            image[i].style.opacity='1';
            i++;
        },5000);
    }

    // static slider css remove when banner images dynamic //
    const images = document.querySelectorAll('.banner-image img');
    let currentIndex = 0;

    function fadeInImage(index) {
        images[index].classList.add('active');
    }

    function fadeOutImage(index) {
        images[index].classList.remove('active');
    }

    function nextImage() {
        fadeOutImage(currentIndex);
        currentIndex = (currentIndex + 1) % images.length;
        fadeInImage(currentIndex);
    }

    setInterval(nextImage, 3000); // Change image every 3 seconds
    fadeInImage(currentIndex); // Initial fade in
    // static slider css remove when banner images dynamic //
    
  }

  navigateToPage(support) {
    if (
          (navigator.userAgent.includes('Mac') && navigator.userAgent.includes('Safari')) ||
          (navigator.userAgent.includes('iPhone') && navigator.userAgent.includes('Safari'))
        ) {
          setTimeout(function () {
            window.location.reload();
          }, 500);
        } 
    this.router.navigateToRoute(support);
  }

  submit()
      {
        this.errors = null;
        this.controller.validate()
        .then(result => {
          if (result.valid) {
            
            var employee = {
              "UserName": this.username,             
              "Password": this.password,
              "RememberMe": this.RememberMe,
            };

            this.loginService.attemptAuth(this.type, employee)
              .then(data => 
                {
                  if(data.EmployeeID > 0){
                    window.localStorage['UserName'] = data.UserName;
                    window.localStorage['Password'] = data.Password;
                    window.localStorage['RememberMe'] = data.RememberMe;
                    window.localStorage['EmployeeClass'] = data.EmployeeClass;
                    this.msalService = window.localStorage['MsalService'] = false;
                    this.jwtService.saveSignInService(this.msalService);
                    this.router.navigateToRoute('dashboard');
                  }    
                  else{
                    if(data.LogInValidation === true){
                      this.errors = "Please login with your o365 account.";                                                           
                    }else{
                      this.errors = "Invalid credentials";
                    }
                  }          
                  
                }
                )
              .catch(promise => {
                promise.then(err => this.errors = err.errors)
              });
          }
        })
        if (
          (navigator.userAgent.includes('Mac') && navigator.userAgent.includes('Safari')) ||
          (navigator.userAgent.includes('iPhone') && navigator.userAgent.includes('Safari'))
        ) {
          setTimeout(function () {
            location.reload();
          }, 1000);
        }
      } 

      txtPassWord=document.getElementById("txtPassWord");
      toggleBtn=document.querySelector("toggleBtn");

    togglePassword() {
        if(txtPassWord.type === "password"){
          txtPassWord.setAttribute('type','text');
           toggleBtn.classList.add('hide');
      }
      else{
         txtPassWord.setAttribute('type','password');
         toggleBtn.classList.remove('hide');
      }
    }

    detached() {
        // Clear the interval when navigating away from the component
        clearInterval(this.interval);
    }
 }
